import home from "./dispatcher/home";
import houses from "./dispatcher/houses";
import requests from "./dispatcher/requests";
import questions from "./dispatcher/questions";
import notifications from "./dispatcher/notifications";

export default function(instance) {
	return {
		...requests(instance),
		...home(instance),
		...houses(instance),
		...questions(instance),
		...notifications(instance),
	};
}
